import { Trans } from "@lingui/macro"
import React, { useEffect } from "react"
import { connect } from "react-redux"

const Legal = ({ updateNavColor }) => {
  useEffect(() => {
    updateNavColor()
  }, [])
  return (
    <div className="row">
      <div className="legal-container col-10 offset-1">
        <div className="main-title">
          <Trans>Privacy Policy</Trans>
        </div>
        <div className="title">
          1.<Trans>Terms of use</Trans>
        </div>
        <div className="text">
          <Trans>
            Hôtel Au Charme Rabelaisien provides its services under reserve that
            you to respect the present conditions of use, that can be changed at
            any time by Hôtel Au Charme Rabelaisien.
          </Trans>
        </div>
        <div className="title">
          2. <Trans>Intellectual property</Trans>
        </div>
        <div className="text">
          <div>
            <Trans>
              According to the laws poverning intellectual property, the
              reproduction or the use of elements found on this internet site,
              whether complete or partial, is strictly forbidden. Only are
              maintained the legal exceptions among which personal
              non-commercial use only (representation in the family circle, or
              private copy or the right to a short quotation.)
            </Trans>
          </div>
          <div>
            <Trans>
              Thus, all the texts, photos, logos, brands and other elements
              reproduced on this site are protected by the right of intellectual
              property (Right author, right(straight) neighbour, right(law) of
              the marks(brands)).
            </Trans>
          </div>
          <div>
            <Trans>
              Any total or partial of this site by any means whatsoever, without
              the express permission of the operator of the website is
              prohibited and constitutes counterfeit sanctioned by articles L
              335-2 and following of the Intellectual Property Code.
            </Trans>
          </div>
          <div>
            <Trans>
              Same is true of any databases contained on the website which
              protected by the provisions of the Act of 11 July 1998 transposing
              into the Intellectual Property Code (IPC) of the European
              directive of 11 March 1996 on the Legal Protection of Databases
            </Trans>
          </div>
          <div className="title">
            3.<Trans>Personal datas</Trans>
          </div>
          <div className="text">
            <Trans>
              Hôtel Au Charme Rabelaisien complies fully with all applicable
              data protection and consumer legislation, and will treat all your
              personal information as confidential.We will not pass on your
              personal details to third parties. Hôtel Au Charme Rabelaisien
              makes a commitment to assure everyone who will make the demand a
              right of access, rectification and deletion of the datas
              concerning him/her.
            </Trans>
          </div>
          <div className="title">
            4. <Trans>Responsabilities</Trans>
          </div>
          <div className="text">
            <div>
              Hôtel Au Charme Rabelaisien decline any responsibility for the
              difficulties met during the access to its site or for any failure
              of communication.
            </div>
            <div>
              Hôtel Au Charme Rabelaisien has the right to modify, to interrupt
              the site temporarily or permanently ( whether completely or
              partially ) , without previous notice. Hôtel Au Charme Rabelaisien
              can be on no account considered as person in charge against you or
              against a third party of any modification, interruption or
              suspension of the site.
            </div>
          </div>
          <div className="title">
            5. <Trans>Links</Trans>
          </div>
          <div className="text">
            <div>
              <Trans>
                You are free to establish links towards this site, as long as
                they don’t affect the interests and the image of its editor. You
                are allowed to establish links towards the pages of this site ,
                only if they are in the followinf formats : html, php, xml.
              </Trans>
            </div>
            <div>
              <Trans>
                The practice of framing is strictly forbidden, as well as any
                other practice or technique which could confuse people.
              </Trans>
            </div>
            <div>
              <Trans>
                Deep links towards documents in the pdf, doc, or rtf formats are
                prohibited. In fact, those types of documents don’t allow people
                to read the conditions of use.
              </Trans>
            </div>
            <div>
              <Trans>
                Hôtel Au Charme Rabelaisien is absolutely not responsible for
                the contents of Internet sites that can be reached through its
                website.
              </Trans>
            </div>
            <div>
              <Trans>Enjoy your visit.</Trans>
            </div>
          </div>
          <div className="title">
            6.
            <Trans>Cookies</Trans>
          </div>
          <div style={{ marginLeft: "25px" }}>
            <div className="text">
              <div>
                <Trans>
                  A cookie is a little file which a website sends to the browser
                  and saves on the User’s computer when visiting a website.
                  Cookies are used in order to allow or enhance the running of
                  the website, but also to gain information on the Site or on
                  the User’s web-browsing activities.
                </Trans>
              </div>
            </div>
            <div className="title">
              a. <Trans>Technical cookies</Trans>
            </div>
            <div className="text">
              <Trans>
                These cookies are essential to allow the User to browse on the
                Site and use its functionalities, such as the access to reserved
                areas of the Site (cookie of authentication). The strictly
                necessary cookies are used to record a univocal identifier in
                order to manage and identify the User with respect to other
                Users visiting the Site in the same moment, providing the User
                with a consistent and precise service (e.g. the language
                selection). These cookies are necessary to the correct working
                of the Site and to record the choices expressed by the User, to
                distribute requests on different servers, to record when the
                User gives his/her authorization or expresses the consent to
                specific options (e.g. newsletters), to allow the User to
                visualize contents and video through Adobe Flash Player.
              </Trans>
            </div>
            <div className="title">
              b. <Trans>First Party Performance and Analytics Cookies</Trans>
            </div>
            <div className="text">
              <Trans>
                These cookies can be session cookies or persistent cookies and
                their use is limited to the performance and improvement of the
                Site. These cookies allow to recognize, measure and keep track
                of visitors to the Site, thus allowing the improvement of the
                Site, for example establishing whether the User is able to
                easily find the desired information, or by identifying which
                aspects of the Site are of major interest. These cookies are
                used by Au Charme Rabelaisien to process statistical and
                anonymous analysis on how Users navigate on the Site, the
                overall number of pages visited or the number of clicks made on
                a page while browsing. These first party cookies are considered
                by the privacy legislation as equal to technical cookies,
                therefore they do not require the consent of the User.
              </Trans>
            </div>
            <div className="title">
              c. <Trans>Third Party Analytics Cookies</Trans>
            </div>
            <div className="text">
              <Trans>
                Au Charme Rabelaisien may also use analytics and performance
                cookies from third parties on the Site, including those of
                Google Analytics, as indicated below. These cookies collect
                information on the ways in which the User uses the Site,
                including, for example, the pages visited by the User.
                Furthermore, third-party analytics cookies can track the use
                that Users make of the Site and allow Au Charme Rabelaisien to
                make improvements to the Site by verifying, for example, whether
                the User is able to easily find the information sought or,
                again, identify the aspects of the Site that are most
                interesting. These cookies, with appropriate mechanisms to
                reduce the personal data collected (such as deletion of part of
                the User’s IP address) are used by The St. Regis Venice to
                process analysis and statistics, exclusively in anonymous mode,
                on how Users browse the Site, the number of pages visited and
                clicks made on a page during navigation. Au Charme Rabelaisien
                treats the results of these analysis only if the third party
                uses cookies in connection with the browser installed on the
                computer or on other devices used by the User to browse the
                Site.
              </Trans>
            </div>
            {/* <div className="text">d. <Trans>How to disable cookies on the browser</Trans></div>
                        <div className="text">
                            <Trans>
                            Apart from the choice to accept or refuse the cookies used by the Site for additional purposes 
                            by means of the mechanism illustrated in this Privacy Policy, in the future the User may disable 
                            from the browser the cookies installed by the Site also by means of the setting of the same browser. 
                            The majority of web browsers are set to accept cookies. However, if so wished, the User may set 
                            his/her browser to limit the number of cookies accepted or to block all cookies, by changing 
                            the browser’s settings with the following procedures:
                            </Trans>
                        </div> */}
            <div className="title">
              d. <Trans>How to disable cookies on the browser</Trans>
            </div>
            <div className="text">
              <Trans>
                Apart from the choice to accept or refuse the cookies used by
                the Site for additional purposes by means of the mechanism
                illustrated in this Privacy Policy, in the future the User may
                disable from the browser the cookies installed by the Site also
                by means of the setting of the same browser. The majority of web
                browsers are set to accept cookies. However, if so wished, the
                User may set his/her browser to limit the number of cookies
                accepted or to block all cookies, by changing the browser’s
                settings with the following procedures:
              </Trans>
              <div>
                <ul>
                  <li>
                    <strong>Microsoft Internet Explorer</strong>
                    <Trans>
                      : Click “Tools” in the top right corner and select
                      “Internet settings”. In the pop-up select “Privacy”: here
                      the User may change the cookies settings.
                    </Trans>
                  </li>
                  <li>
                    <strong>Google Chrome</strong>
                    <Trans>
                      : Click the “wrench” in the top right corner and select
                      “Settings”. Then select “Show advanced settings” and
                      change the “Privacy” settings.
                    </Trans>
                  </li>
                  <li>
                    <strong>Mozilla Firefox</strong>
                    <Trans>
                      : In the top left menu select “Settings”. In the pop-up
                      window select “Privacy”: here the User may change the
                      cookies settings.
                    </Trans>
                  </li>
                  <li>
                    <strong>Safari</strong>
                    <Trans>
                      : In the top right menu select “Preferences”. Then select
                      “Security” and change the cookies settings.{" "}
                    </Trans>
                  </li>
                </ul>
              </div>
              <Trans>
                In order to carry out such operation in the mobile telephone,
                please consult the handbook of the device. Please be aware that
                by disabling all the cookies on the Site, certain
                functionalities of the Site may not be available.
              </Trans>
            </div>
            <div className="title">
              e. <Trans>Further information on cookies</Trans>
            </div>
            <div className="text">
              <Trans>The website</Trans>{" "}
              <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>
              <Trans>
                {" "}
                contains the instructions for the management of cookies of the
                most used browsers; alternatively, it is possible to consult the
                documentation related to the software used on the device.
              </Trans>
            </div>
          </div>
          <div className="title">
            7. <Trans>Amendments to the Privacy Policy</Trans>
          </div>
          <div className="text">
            <Trans>
              Au Charme Rabelaisien may amend, from time to time, this Privacy
              Policy, by publishing the amendments on the Site. We invite Users
              to check periodically such updates.
            </Trans>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateNavColor: () =>
      dispatch({ type: `UPDATE_NAV_COLOR`, payload: "black" }),
  }
}

export default connect(null, mapDispatchToProps)(Legal)
